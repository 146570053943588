import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Client } from '../../client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './projectPage.css';
import ImageFade from '../../components/imageFade';

const ProjectPage = () => {
  const [project, setProject] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    async function fetchProject() {
      const response = await Client.getEntries({
        content_type: 'project',
        'fields.slug': slug
      });
      setProject(response.items[0]);
    }
    fetchProject().then(() => {
      if (project.fields) {
        ImageFade();
      } else {
        setTimeout(() => {
          ImageFade();
        }, 100);
      }
    })
  }, [slug]);

  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const { file, title } = node.data.target.fields;
        const mimeType = file.contentType;
        const url = file.url;
        if (mimeType === 'image/jpeg' || mimeType === 'image/png') {
          return <img className='mb-4 mx-auto fade opacity-0' src={url} alt={title} />;
        } else {
          return <a href={url}>{title}</a>;
        }
      },
      'paragraph': (node, children) => {
        const textContent = node.content[0].value;
        if (textContent.includes('youtube.com/watch?v=') || textContent.includes('vimeo.com/')) {
          if (textContent.includes('youtube.com/watch?v=')) {
            const videoId = textContent.split('youtube.com/watch?v=')[1];
            return (
              <div className='video-embed youtube fade opacity-0'>
                <iframe
                  width='560'
                  height='315'
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            );
          } else if (textContent.includes('vimeo.com/')) {
            const videoId = textContent.split('vimeo.com/')[1];
            return (
              <div className='video-embed vimeo fade opacity-0'>
                <iframe
                  src={`https://player.vimeo.com/video/${videoId}`}
                  width='640'
                  height='360'
                  frameBorder='0'
                  title='Vimeo video player'
                  allow='autoplay; fullscreen; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return <p className='text-base md:text-lg fade opacity-0'>{children}</p>;
        }
      }
    }
  };

  return (
    <div className='px-4 py-8'>
      <div className="grid grid-cols-1 gap-4">
        <h1 className='mt-36 md:mt-50vh mb-16 text-center md:text-left text-base md:text-lg fade opacity-0'>{project.fields ? project.fields.title : 'Loading...'}</h1>
        <div className='col-span-1'>
          {project.fields
            ? documentToReactComponents(
                project.fields.content,
                options // Pass the options object to customDocumentToReactComponents
              )
            : null}
        </div>
      </div>

    </div>
  );
};

export default ProjectPage;

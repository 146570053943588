import React from 'react';
import { Routes, Route} from "react-router-dom";

import ProjectsPage from './pages/projects/projects';
import AboutPage from './pages/about/about';

import ProjectPage from './pages/projectPage/projectPage';
import Home from './pages/home/home';
import Layout from './components/layout';
import AuthGate from './components/authGate';

function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthGate />}>
        <Route index element={<Home />} />
        <Route path="projects" element={<ProjectsPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route exact path="/projects/:slug" element={<ProjectPage />} />
        

        {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
    
  );
}

export default App;


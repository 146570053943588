import React, { useState, useEffect } from 'react'
import { Link, useLocation, Outlet } from 'react-router-dom';


import MenuModal from './menu';
import ContactModal from './contact';
import Modal from 'react-modal';


import './layout.css'

const AddClassOnMount = () => {
  useEffect(() => {
    setTimeout(() => {
      document.body.classList.add('loaded');  
    }, 50);
  }, []);
}

const Layout = () => {

  Modal.setAppElement('#root');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const closeAllModals = () => {
      setModalIsOpen(false);
      setContactModalIsOpen(false);
    };

    closeAllModals();
  }, [location]);

  const openModal = () => {
    if (modalIsOpen) {
      setModalIsOpen(false);
    } else {
      setModalIsOpen(true);
    }

    if (contactModalIsOpen) {
      setContactModalIsOpen(false);
    }
  };

  const handleContactClick = () => {
    setContactModalIsOpen(!contactModalIsOpen);
    if (modalIsOpen) {
      setModalIsOpen(false);
    }
  };

  return (
    <div className='bg-white min-h-[100vh]'>
      <header className="fixed z-50 top-0 left-0 w-full text-white mix-blend-difference">
        <div className="grid grid-cols-3 justify-between items-center px-4 py-2 gap-4">
          <div className="block col-span-2 md:hidden">
            <h1 className='text-base md:text-lg'><Link to=''>Rose Maisie Willoughby</Link></h1>
          </div>
          <div className='hidden md:block load transition-opacity duration-500'>
            <Link to='/' className='mr-4 text-lg'>Rose</Link>
          </div>
          <div className='hidden md:block load transition-opacity duration-500 delay-200'>
            <Link to='/about' className="mr-4 text-lg">Maisie</Link>
          </div>
          <div className="">
            <Link to='/projects' className="mr-4 load transition-opacity duration-500 delay-[400ms] text-lg float-left hidden md:inline">Willoughby</Link>
            <button onClick={openModal} className={`float-right load transition-opacity duration-500 delay-[600ms] menu-button ${modalIsOpen?'menu-open':''}`}>
              <span className="menu-bar"></span>
              <span className="menu-bar"></span>
            </button>
          </div>
        </div>
      </header>

      <Outlet />

      <footer className="fixed z-50 bottom-0 left-0 w-full text-white mix-blend-difference gap-4">
        <div className="grid grid-cols-3 justify-between items-center px-4 py-2">
          <div>
            <button className='text-base md:text-lg load transition-opacity duration-500' onClick={handleContactClick}>Contact</button>
          </div>
          <div className='hidden md:block'>
            <h5 className='text-lg load transition-opacity duration-500 delay-200'>LDN — NYC</h5>
          </div>
        </div>
      </footer>

      <ContactModal modalIsOpen={contactModalIsOpen} setModalIsOpen={setContactModalIsOpen} />
      <MenuModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <AddClassOnMount />
    </div>
  );
}

export default Layout

import React, { useEffect, useState } from 'react';
import { Client } from '../client';
import { Link } from 'react-router-dom';

function ProjectCard({ title, description, imageUrl }) {

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setLoaded(true);
    };
    image.src = imageUrl;
  }, [imageUrl]);
  
  return (
    <div className="bg-white overflow-hidden relative">
      <div
        className={`transition-opacity duration-1000 absolute top-0 left-0 right-0 h-48 md:h-64 ${loaded ? 'opacity-100' : 'opacity-0'}`}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      ></div>
      <div className="relative pt-52 md:pt-72">
        <h2 className="text-base md:text-lg mb-2">{title}</h2>
      </div>
    </div>
  );
}


function ProjectGrid() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function fetchProjects() {
      const response = await Client.getEntries({
        content_type: 'project',
      });

      setProjects(response.items);
    }

    fetchProjects();
  }, []);

  console.log(projects);

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-16">
        {projects.map((project) => (
          <Link to={`/projects/${project.fields.slug}`} key={project.fields.slug}>
            <ProjectCard key={project.sys.id} title={project.fields.title} imageUrl={project.fields.coverImage.fields.file.url} />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ProjectGrid;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Client } from '../client';

import './menu.css';

const ContactModal = ({ modalIsOpen, setModalIsOpen }) => {

  const [aboutPage, setAboutPage] = useState([]);

  useEffect(() => {
    async function getAboutPage() {
      const response = await Client.getEntries({
        content_type: 'staticInfo',
      });

      setAboutPage(response.items);
    }

    getAboutPage();
  }, []);

  let singleAboutPage = {};
  if (aboutPage && aboutPage.length > 0) {
    singleAboutPage = aboutPage[0];
  }
  
  return (
    <Modal
      closeTimeoutMS={150}
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={`modal ${modalIsOpen ? 'is-open' : ''}`}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      contentLabel="Menu"
    >

      <div
      className="h-screen w-screen flex items-center justify-center bg-no-repeat bg-center bg-cover"
      
    >
      <div className="w-full px-4 grid grid-cols-3 gap-4">
        <div className="hidden md:block col-span-1">
        </div>
        
        <div className="col-span-3 md:col-span-2 text-center md:text-left text-base md:text-lg">
            <h4 className='mb-0'>
              {singleAboutPage.fields?.emailAddress && (
                <Link to={`mailto:${singleAboutPage.fields?.emailAddress}`}>
                  {singleAboutPage.fields?.emailAddress}
                </Link>
              )}
            </h4>
            <h4>
              {singleAboutPage.fields?.telephone && (
                <Link to={`tel:${singleAboutPage.fields?.telephone}`}>
                  {singleAboutPage.fields?.telephone}
                </Link>
              )}
            </h4>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
import React, { useState, useEffect } from 'react';
import { Client } from '../client';
import { Link } from 'react-router-dom';
import './projectIndex.css';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const customData = {
  fields: {
    slug: 'my-project',
    title: 'Senior Vice President Global Creative, Brand Marketing + Communications',
    coverImage: {
      fields: {
        file: {
          url: 'https://via.placeholder.com/600x400.png?text=My+Project+Cover+Image'
        }
      }
    }
  }
};

const ProjectsIndex = () => {
  const [projects, setProjects] = useState([customData]);
  let [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  let [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [showCustomData, setShowCustomData] = useState(true);
  const [showImage, setShowImage] = useState(false);

  const [aboutPage, setAboutPage] = useState([]);

  // get about page data
  useEffect(() => {
    async function getAboutPage() {
      const response = await Client.getEntries({
        content_type: 'staticInfo',
      });
      setAboutPage(response.items);
    }
    getAboutPage();
  }, []);

  //assemble single aboutpage entry
  let singleAboutPage = {};
  if (aboutPage && aboutPage.length > 0) {
    singleAboutPage = aboutPage[0];
  }

  //get projects
  useEffect(() => {
    Client.getEntries({
      content_type: 'project',
      'fields.featured': true
    }).then((response) => {
      setProjects(response.items);
    });
  }, []);

  //when show custom data changes, apply logic to show image,so it fades correctly on first change.
  useEffect(() => {
    if (showCustomData) {
      setShowImage(false);
    } else {
      setShowImage(true);
    }
  },  [showCustomData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlideIndex((currentSlideIndex + 1) % projects.length);
    }, 3000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (showCustomData === false) {
      setShowImage(false);
      setTimeout(() => {
        setCurrentProjectIndex(currentSlideIndex);
        setShowImage(true);
      }, 400);
    }
  }, [currentSlideIndex]);

  useEffect(() => {
    setTimeout(() => {
      setShowCustomData(false);    
    }, 3000); 
  });

  const handleProjectClick = () => {
    setShowImage(false);
    setCurrentSlideIndex((currentSlideIndex + 1) % projects.length);
    setShowCustomData(false);
  };

  if (!projects || projects.length === 0) {
    return null;
  }

  const currentProject = projects[currentProjectIndex];
  
  if (!currentProject || !currentProject.fields) {
    return null;
  }

  const projectInfo = currentProject.fields;
  const imageSrc = `https:${projectInfo.coverImage.fields.file.url}`;

  let featuredImageSize = 'cover';
  switch (projectInfo.featuredImageLayout) {
    case 'cover':
      featuredImageSize = 'cover';
      break;
    case 'contain':
      featuredImageSize = 'contain';
      break;
    case '75 percent':
      featuredImageSize = '75%';
      break;
    default:
      featuredImageSize = 'cover';
      break;
  }

  console.log(projectInfo);

  // Create an array to store the preloaded images
  const images = [];

  // Preload the project images
  for (let i = 0; i < projects.length; i++) {
    const project = projects[i];
    const projectImageUrl = project.fields.coverImage.fields.file.url;
    const projectImage = new Image();
    projectImage.src = `https:${projectImageUrl}`;
    images.push(projectImage);
  }
  
  return (
    
    <div className='h-screen w-screen flex items-center justify-center'>
      <div className={`duration-500 absolute w-full h-full bg-no-repeat bg-center ${
          featuredImageSize === 'cover'
            ? 'bg-cover'
            : featuredImageSize === 'contain'
            ? 'bg-contain'
            : 'bg-auto-75'
        } transition-opacity ${showImage ? 'opacity-100' : 'opacity-0'}`}
      style={{
        backgroundImage: `url(${showCustomData ? null : imageSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={handleProjectClick}
    ></div>
      <div className="w-full px-4 grid grid-cols-3 gap-4">
        <div className="col-span-1 hidden md:flex">
          <h2 className='load transition-opacity duration-500 mix-blend-difference text-white text-lg load'>Selected Work</h2>
        </div>
        <div className="col-span-3 md:col-span-2">
          <Link to={`/projects/${projectInfo.slug}`} className='hover:underline'>
            {
              showCustomData ? 
                <div className="landingIntro load transition-opacity duration-500 delay-200 text-base md:text-lg mix-blend-difference text-white">
                   {singleAboutPage.fields
                    ? documentToReactComponents(
                        singleAboutPage.fields.homepageIntro,
                      )
                    : null}
                </div> : null
            }
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectsIndex;
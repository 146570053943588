import Carosel from "../../components/carosel";
import ProjectsIndex from "../../components/projetsIndex";



function Home() {
  return (
    <div className="Home">
      <ProjectsIndex></ProjectsIndex>
    </div>
  );
}

export default Home;
import React, { useState, useEffect } from 'react';
import { Client } from '../client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const AboutContent = () => {
  
  const [aboutContent, setAboutContent] = useState([]);
  const [aboutContentIsOpen, setAboutContentIsOpen] = useState(false);
  const [aboutContentModalIsOpen, setAboutContentModalIsOpen] = useState(false);

  const [aboutPage, setAboutPage] = useState([]);

  // get about page data
  useEffect(() => {
    async function getAboutPage() {
      const response = await Client.getEntries({
        content_type: 'staticInfo',
      });
      setAboutPage(response.items);
    }
    getAboutPage();
  }, []);

  //assemble single aboutpage entry
  let singleAboutPage = {};
  if (aboutPage && aboutPage.length > 0) {
    singleAboutPage = aboutPage[0];
  }

  console.log(singleAboutPage);

  return (
    <div className='px-4 py-8'>
      <div className='grid grid-cols-3 gap-4'>  
        <div className='hide md:col-span-1'>
        </div>
        <div className='col-span-3 md:hidden'>
          <h1 className='text-center mt-36 text-base font-bold'>About</h1>
        </div>
        <div className='col-span-3 md:col-span-2'>
          <p className='mt-8 text-base md:text-lg'>{singleAboutPage.fields ? documentToReactComponents(singleAboutPage.fields.aboutPageContent) : '' }</p> 
        </div>
      </div>
    </div>
  )
 
}

export default AboutContent;
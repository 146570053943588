import react from "react";

function ImageFade() {
  
  const images = document.querySelectorAll('.fade');
  
  const options = {
    rootMargin: '0px',
    threshold: 0.25,
  };
  
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove('opacity-0');
        observer.unobserve(entry.target);
      }
    });
  }, options);
  
  images.forEach((image) => {
    observer.observe(image);
  });
}

export default ImageFade
import React from 'react';
import AboutContent from '../../components/aboutContent';

const AboutPage = () => {
  return (
    <AboutContent />
  )
}

export default AboutPage

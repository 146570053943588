import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import './menu.css';

const MenuModal = ({ modalIsOpen, setModalIsOpen }) => {
  return (
    <Modal
      closeTimeoutMS={150}
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className={`modal ${modalIsOpen ? 'is-open' : ''}`}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      contentLabel="Menu"
    >
      <div className="mx-auto w-max flex justify-center h-screen">
        <div className="flex flex-col justify-evenly">
          <div className="text-center">
            <Link to='/projects' className="text-black font-600 text-lg md:text-3xl" onClick={() => setModalIsOpen(false)}>Selected Projects</Link>
          </div>
          <div className='text-center'>
            <Link to='/about' className="text-black font-600 text-lg md:text-3xl	" onClick={() => setModalIsOpen(false)}>About</Link>
          </div>  
        </div>
      </div>
      {/* <button className='p-4' onClick={() => setModalIsOpen(false)}>Close</button> */}
    </Modal>
  );
};

export default MenuModal;
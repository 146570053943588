import React from 'react'
import ProjectGrid from '../../components/project-grid'

const ProjectsPage = () => {
  return (
    <div className='px-4 py-8'>
      <div className='w-full md:hidden'>
        <h1 className='text-center mt-36 text-base font-bold'>Selected Projects</h1>
      </div>
      <ProjectGrid></ProjectGrid> 
    </div>
  )
}

export default ProjectsPage

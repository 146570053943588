import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './layout';

const AuthGate = () => {
  const isAuthenticated = localStorage.getItem('authenticated');
  const navigate = useNavigate();

  const [password, setPassword] = useState('');

  if (isAuthenticated) {
    return <Layout />; //or your authenticated component
  }

  const handleSubmit = evt => {
    evt.preventDefault();
    const correctPassword = 'stratus'; 
    if (password !== correctPassword) {
      navigate('/login'); 
    } else {
      localStorage.setItem('authenticated', true);
      setPassword('');
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
      <input type="password" onChange={e => setPassword(e.target.value)} value={password} style={{ borderBottom: "1px solid", marginBottom: "20px" }}/>
      <input type="submit" value="Submit" />
    </form>
  );
}

export default AuthGate;